<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Цены
      </h1>
      <p class="mb-2 pb-75">
        Хранение файлов в архиве бесплатно. Вы будете получать баллы за вклад в пополнение архива и за скачивание Ваших файлов другими пользователями.
        <br>
        Если же баллов на Вашем счету не достаточно, то Вы всегда можете приобрести баллы за рубли.
      </p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="12">
            <b-card
              class="popular"
              align="center"
            >
              <div
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  ХИТ
                </b-badge>
              </div>
              <!--/ img -->
              <h3>Минимальное количество 3000<feather-icon
                icon="WindIcon"
                color="red"
                size="22"
              /></h3>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">3000<feather-icon
                    icon="WindIcon"
                    color="red"
                    size="33"
                  /> = 100<span style="color: red; font-size: 33px">₽</span></span>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                :to="{ name: 'app-points-add' }"
              >
                Пополнить баланс за рубли
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Архив построен по принципам взаимного обмена
              </h3>
              <h5>Как правило пользователям архива достаточно накопленных баллов для бесплатного использования архива</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                :to="{ name: 'apps-excerpt-search-folder', params: { label: 'all', folder: 'all' } }"
              >
                Перейти в архив
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
    }
  },
  created() {
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
